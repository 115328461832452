@import '../../../../scss/theme-bootstrap';

.product-full {
  &__image {
    max-width: 320px;
    margin: 0 auto;
    @include breakpoint($landscape-up) {
      max-width: 100%;
    }
    @include breakpoint($landscape-up) {
      overflow: hidden;
    }
    &--single {
      text-align: center;
    }
  }
  // Hide default image on zoom-in
  &__carousel-slide-img {
    &.zoom--active {
      opacity: 0;
    }
  }
  &__image-img {
    display: block;
    margin: 0 auto;
    @include breakpoint($landscape-up) {
      height: auto;
      max-height: 800px;
    }
    .zoom--active & {
      @include breakpoint($landscape-up) {
        @include opacity(0);
      }
    }
    .slick-active.zoom--active & {
      @include breakpoint($large-up) {
        @include opacity(0);
      }
    }
    &--alt {
      max-width: 100%;
      padding-bottom: 15px;
      @include breakpoint($landscape-up) {
        padding-bottom: 25px;
      }
      @include breakpoint($landscape-up) {
        padding-bottom: 0;
      }
    }
  }
  &__image-single,
  &__carousel-slides {
    @include breakpoint($landscape-up) {
      text-align: center;
      overflow: hidden;
      background: $color-white;
      max-height: 500px;
      max-width: 500px;
      margin-#{$ldirection}: 92px;
    }
  }
  &__carousel-slide {
    display: none; // prevent FOUC
    &:first-child,
    .slick-initialized & {
      display: block;
    }
    .slick-initialized & {
      padding: 0 1px;
    }
  }
  &__image-thumbnails {
    display: none;
    @include breakpoint($landscape-up) {
      @include centerer(false, true);
      display: block;
      #{$ldirection}: 0;
      .product-quickshop & {
        #{$ldirection}: 15px;
      }
    }
    @include breakpoint($large-up) {
      #{$ldirection}: 25px;
      top: 48%;
    }
  }
  &__carousel-thumbnail {
    background-color: $color-white;
    display: block;
    width: 60px;
    height: 60px;
    cursor: pointer;
    margin: 0 0 10px;
    overflow: hidden;
    &.active {
      cursor: default;
      border: 1px solid $color-lighter-grey;
      padding: 2px;
    }
    img,
    .spp-olapic-element-bg {
      @include opacity(0.5);
    }
    &:hover,
    &.active {
      img,
      .spp-olapic-element-bg {
        @include opacity(1);
      }
    }
  }
  &__image-carousel {
    .carousel-controls {
      display: none;
      .product-quickshop & {
        display: block;
      }
    }
    .carousel-dots {
      position: absolute;
      width: 100%;
      bottom: -40px;
      #{$ldirection}: 0;
      @include breakpoint($landscape-up) {
        #{$ldirection}: 240px;
      }
      @include breakpoint($large-up) {
        #{$ldirection}: 310px;
      }
      .slick-dots {
        margin-top: 0;
        @include breakpoint($landscape-up) {
          text-align: $ldirection;
        }
      }
    }
  }
  &__video {
    cursor: pointer;
  }
  &__video-image,
  &__carousel-thumbnail--video {
    position: relative;
    .icon {
      @include centerer(true, true);
      position: absolute;
      font-size: 40px;
      z-index: 1;
      color: inherit;
      @include breakpoint($large-up) {
        font-size: 70px;
      }
    }
  }
  &__carousel-thumbnail--video {
    .icon {
      font-size: 25px;
    }
  }
}
